export const customersMixin = {
    data() {
        return {
            loading: false,
            form: {
                first_name: null,
                last_name: null,
                phone: null,
                email: null,
                city: null,
                is_search_city: false,
            },
        };
    },
    methods: {
        clearForm() {
            for (const key in this.form) {
                this.form[key] = null;
            }
        },
        async fetchCustomer() {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/customers/${this.$route.params.id}/edit`);

                console.log('fetchCustomer ~ data:', data);
                for (const field in this.form) {
                    this.form[field] = data.customer[field];
                }

                this.loading = false;
            } catch (ex) {
                console.log('cant find customer: ' + ex);
                this.loading = false;
                this.$router.push('/error-page');
            }
        },
    },
};
